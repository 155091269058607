<script setup lang="ts">
import type { HeroItemContent } from 'types/blocks';

const root = useRoot();

defineProps<{
  data: HeroItemContent;
}>();
</script>

<template>
  <section
    class="relative"
    :class="root.settings.content.metaMenuEnabled ? 'hero-height-with-meta-menu' : 'hero-height-without-meta-menu'"
  >
    <figure class="pointer-events-none h-full select-none bg-black/75">
      <BaseImage
        v-if="!data?.heroVideo && data?.heroImage"
        class="h-full w-full object-cover"
        :src="useCdnBase(data?.heroImage.url)"
        format="webp"
        loading="eager"
        sizes="100vw lg:100vw"
        :style="
          data?.heroImageBrightness !== undefined && data?.heroImageBrightness !== 0
            ? `filter:brightness(${data?.heroImageBrightness / 100});`
            : ''
        "
        :alt="data?.heroImage?.additionalProperties?.alt"
        :preload="true"
      />
      <video
        v-if="data?.heroVideo"
        autoplay
        loop
        muted
        class="h-full w-full object-cover brightness-50 filter"
        preload="auto"
        playsinline
        :controls="false"
      >
        <source
          :src="useCdnBase(data.heroVideo?.url, true)"
          type="video/mp4"
        />
      </video>
    </figure>
    <div class="container absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 py-20 text-white md:py-40">
      <h1
        v-if="data?.heroHeading"
        class="w-full text-display-1 lg:w-2/4"
      >
        {{ data.heroHeading }}
      </h1>
      <p
        v-if="data?.heroSubHeading"
        class="mt-4 font-base text-h3 font-normal lg:w-2/4"
      >
        {{ data.heroSubHeading }}
      </p>

      <div class="mt-6 flex flex-col gap-4 sm:flex-row sm:gap-6 md:mt-12">
        <BaseButton
          v-for="(link, buttonIndex) in data?.heroCallToAction"
          :key="link.name"
          :variant="buttonIndex === 1 ? 'ghost' : 'primary'"
          class="md:w-fit"
          :to="link.url"
          :title="link.name"
        >
          {{ link.name }}
        </BaseButton>
      </div>
    </div>
  </section>
</template>

<style scoped lang="postcss">
.hero-height-with-meta-menu {
  height: calc(100vh - var(--header-meta-menu-height));
}

.hero-height-without-meta-menu {
  height: calc(100vh - var(--header-height));
}
</style>
